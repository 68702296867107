.c-send-message__item {
  display: flex;
  flex-direction: row;
  height: 100%;
  align-items: center;
}

.c-send-message__message {
  /*float: left;*/
  width: 20%;
  text-align: end;
  font-size: 1em;
}

.c-send-message__form {
  /*float: left;*/
  width: 80%;
}

.message-form__item {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 3px;
}

.message-form__input {
  width: 80%;
  height: 40px;
  border-radius: 10px 0 0 10px;
  padding: 5px;
  border-color: grey;
  border-width: 1px;
}

.message-form__button {
  height: 40px;
  font-size: 0.875em;
  padding: 13px;
  border-radius: 0 10px 10px 0;
  border-style: hidden;
}
