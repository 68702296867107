* {
  box-sizing: border-box;
}

.login-page {
  width: 100vw;
  height: 100vh;
  background-color: rgba(214, 210, 213, 0.075);
}

.form-wrapper {
  width: 30%;
  height:100%;
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  justify-content: center;
  align-items: flex-start;
}

.form {
  height: 40%;
  width: 95%;
  min-height: 230px;
  background-color: white;
  margin: 30% auto;
  border: 1px solid gray;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;

}

.form-control {
  width: 80%;
  font-size: 0.875em;
  text-align: center;
  max-width: 360400px;

}

.form__username-input {
  height: 35px;
  width: 90%;
  border-radius: 1em;
  background-color: #dfdbdb;
  font-size: 0.875rem;
  text-align: center;
  padding: 5px 10px;
  border: 1px solid rgba(118, 118, 118, 0.788);
}


.form__login-button {
  font-size: 1rem;
  width: 50%;
  max-width: 300px;
  padding: 10px 10px;
  margin: 15px auto;
  border-radius: 10px;
}


.form h2 {
  display: block;
  color: rgba(15, 15, 189, 0.767);
  width: 100%;
  text-align: center;
  align-items: center;
  margin: 0.85em auto;

}

@media screen and (max-width: 600px) {
  .form-wrapper {
    width: 100%;
  }

  .form h2 {
    font-size: 1.2rem;
  }

  .form__username-input {
    font-size: 0.7rem;
  }
}