*,
*::before,
*::after {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}


body {
	font-family: "Work Sans", Manrope, Verdana sans-serif;
	font-size: 100%;
}

h2 {
	font-size: 1.875em;
}

p {
	font: size 0.875em;
}

ol,
ul {
	list-style: none;
}

button {
	background-color: rgba(9, 85, 199, 0.13);
	color: rgb(3, 3, 94);
	border-style: hidden;
	transition: all 0.2s;
}

button:hover {
	background-color: rgba(9, 85, 199, 0.719);
	color: antiquewhite;
	cursor: pointer;
}