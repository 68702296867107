* {
  box-sizing: border-box;
}

.c-header__item {
  display: flex;
  flex: 1;
  flex-direction: row nowrap;
  height: 100%;
  font-weight: bolder;
  justify-content: center;
}

.c-header__button__item {
  /*float: left;*/
  width: 25%;
  max-width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.c-header__greetings__item {
  float: right;
  width: 75%;
  display: flex;
  flex-direction: column;
  justify-content:space-evenly;
  align-items: flex-end;
  font-size: 1.5rem;
  color:#3a056b;
  padding-right: 10px;
}
.c-header__greetings__item :first-of-type(p){
  font-size: 5px;
}

.c-header__button {
  width: 70%;
  height: 40%;
  border-radius: 15px;
  font-size: 1em;
}

@media screen and (max-width: 1160px) {
  .c-header__greetings__item {
    display: none;
  }

  .c-header__button__item {
    width: 100%;
  }
}

@media screen and (max-height: 600px) {
  .c-header__greetings__item {
    display: none;
  }

  .c-header__button__item {
    width: 100%;
  }
}
